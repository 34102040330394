<template>
  <div class="blogs">
    <div class="content-header">
      <h1>博客管理<small>标签管理</small></h1>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/' }">后台管理</el-breadcrumb-item>
        <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        <el-breadcrumb-item>标签管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--中间内容-->
    <div class="app-container">
      <div class="box">
        <div class="filter-container">
          <el-form ref="form" :model="tagForm" label-width="80px" inline="true">
            <el-form-item label="标签名：" prop="tagName">
              <el-input v-model="tagForm.tagName" placeholder="请输入标签名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="listTags()">查询标签</el-button>
              <el-button type="primary" v-on:click="resetSearch()">重置</el-button>
              <el-button type="primary" class="butT" @click="handleCreate">添加标签</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="ui container">
          <el-table size="middle" current-row-key="id" :data="dataList" stripe highlight-current-row>
            <!--                        id,用户名，真实姓名，角色，备注，最后登录时间，创建时间-->
            <el-table-column prop="id" min-width="15px" align="center" label="序号" v-if="false"></el-table-column>
            <el-table-column prop="tagName" label="标签名称" align="center"></el-table-column>
            <el-table-column label="管理" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">编辑</el-button>
                <!--              <el-button size="mini" type="info" @click="handleForbidden(scope.row)">禁用</el-button>-->
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              class="pagiantion"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.page"
              :page-sizes="[2,5,10,15]"
              :page-size="pagination.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.total">
            </el-pagination>
          </div>

            <el-dialog v-bind:title="dialogTile" :visible.sync="dialogFormVisible">
              <el-form ref="operateTagFormData" :model="operateTagFormData" label-width="80px" inline="true" v-bind:rules="rules">
                <el-form-item label="id：" prop="tagName" v-if="false">
                  <el-input v-model="operateTagFormData.id" placeholder="请输入Id"></el-input>
                </el-form-item>
                <el-form-item label="标签名：" prop="tagName">
                  <el-input v-model="operateTagFormData.tagName" placeholder="请输入标签名"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="cancelInsertTag()">取消</el-button>
                <el-button type="primary" @click="submitTag()">确定</el-button>
              </div>
            </el-dialog>

        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogTile: '',
      pagination: { // 分页相关模型数据
        page: 1, // 当前页码
        size: 10, // 每页显示的记录数
        total: 0, // 总记录数
        queryString: null // 查询条件
      },
      tagForm: {
        tagName: '',
      },
      operateTagFormData: {  // 添加表单数据
        id: '',
        tagName: ''
      },
      dialogFormVisible: false, // 增加表单是否可见
      dialogFormVisible4Edit: false, // 编辑表单是否可见
      dataList: [], // 当前页要展示的分页列表数据
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      rules: { // 校验规则
        // 分类名称
        tagName: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.listTags()
    // this.getUser()
  },
  methods: {
    // 分页查询
    async listTags() {
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        page: this.pagination.page,
        size: this.pagination.size,
        tagName: this.tagForm.tagName
      }
      const res = await this.$http.get('/tag/listTags', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        return this.$message.error('获取标签列表失败！')
      }
      this.pagination.total = res.data.total;
      this.dataList = res.data.list
    },

    resetSearch() {
      this.tagForm.tagName = ''
      this.listTags();
    },

   /* getUser() {
      this.user = window.sessionStorage.getItem('user')
      this.nickname = JSON.parse(this.user).nickname
      this.avatar = JSON.parse(this.user).avatar
    },*/
    // 切换页码
    handleCurrentChange(currentPage) {
      // 设置最新的页码
      this.pagination.page = currentPage
      // 重新调用listTags方法进行分页查询
      this.listTags()
    },
    handleSizeChange(newSize) {
      this.pagination.size = newSize
      this.listTags()
    },
    // 重置表单
    resetForm() {
      this.operateTagFormData.id = ''
      this.operateTagFormData.tagName = '' // 重置表格数据
   //   this.$refs.operateTagFormData.resetFields()
    },

    // 弹出添加窗口
    handleCreate() {
      this.dialogFormVisible = true
      this.dialogTile = '新增标签'
      this.resetForm()
    },

    // 添加标签
     submitTag() {
      this.$refs.operateTagFormData.validate(async (valid) => {
        if (valid) {
          if (this.dialogTile === '新增标签') {
            const res = await this.$http.put('/tag/insertTag', this.operateTagFormData);
            if (this.$constant.RESPONSE_CODE.SUCCESS === res.code) {
              this.listTags()
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.dialogFormVisible = false;
            } else {
              this.$message.error("添加失败")
            }
          } else if (this.dialogTile === '修改标签') {
            const res = await this.$http.post('/tag/updateTag', this.operateTagFormData);
            if (this.$constant.RESPONSE_CODE.SUCCESS === res.code) {
              this.listTags()
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.dialogFormVisible = false;
              this.resetForm();
            } else {
              this.$message.error("修改失败")
            }
          }
        } else {
          this.$message.error('校验失败，请检查输入格式')
          return false
        }
      })
    },

    cancelInsertTag(operateTagFormData) {
      this.dialogFormVisible = false;
      this.resetForm();
    },
    handleDelete(rowData) {
      this.$confirm('确定删除该标签嘛？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete("/tag/deleteTag" , {id: rowData.id});
        if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.listTags();
        } else {
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleUpdate(rowData) {
      this.dialogFormVisible = true
      this.dialogTile = '修改标签'
      // 回显数据
      this.operateTagFormData.tagName = rowData.tagName;
      this.operateTagFormData.id = rowData.id;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/me.css";
@import "../../../assets/css/style.css";
</style>
